import logo from './logo.svg';
import Dashboard from './Dashboard/Dashboard'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux'
import { setAuth, selectValid, selectMember, selectToken } from './features/authSlice/authSlice'
import Container from '@mui/material/Container';
import VideoPlayer from "react-background-video-player";
import axios from 'axios'
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


function App() {
  const token = Cookies.get('token');
  const dispatch = useDispatch();
  const isValid = useSelector(selectValid);
  const [loginButton, setLoginButton] = useState(true);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    var data = JSON.stringify({
      "token": token
    });

    var config = {
      method: 'post',
      url: process.env.REACT_APP_VATIL_API + '/verify',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.data) {
          dispatch(setAuth({ member: response.data, token: token, valid: true }));
        }
      })
      .catch(function (error) {
        setLoginButton(false);
      });
  }, [isValid]);


  if (isMobile) {
    return (
      <Container>
        <VideoPlayer
          className="video"
          src={
            "https://vatil.org.il/video/vatil.mp4"
          }
          autoPlay={true}
          muted={true}
          containerWidth={100}
          containerHeigh={100}
        />
        <div className="center">
          <h3>VATSIM Israel Dashboard doesn't support mobile devices yet, please use PC.</h3>
        </div>
      </Container>
    )
  } else {
    if (!isValid) {
      return (
        <Container maxWidth="xxl">
          <VideoPlayer
            className="video"
            src={
              "https://vatil.org.il/video/vatil.mp4"
            }
            autoPlay={true}
            muted={true}
            containerWidth={100}
            containerHeigh={100}
          />
          <div className="center">
            <Grid container spacing={2} >
              <Grid item xs={0}>
              </Grid>
              <Grid item xs={12}>
                <h1>Welcome to VATSIM Israel Dashboard</h1>
              </Grid>
              <Grid item xs={0}>
              </Grid>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={5}>
                <Button href="https://api.vatil.org.il/auth/vatsim" variant="contained" disabled={loginButton} >Login Using VATSIM</Button>
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
          </div>
        </Container>
      );
    } else {
      return (
        <Dashboard />
      )
    }
  }




}

export default App;
